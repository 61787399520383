var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.globalDataFetched)?_c('div',{staticClass:"page-home"},[_c('div',{staticClass:"max-w-8xl mx-auto"},[_c('div',{staticClass:"py-2 px-2 flex flex-col justify-center items-center"},[_c('Breadcrumb')],1),_c('div',{staticClass:"px-8"},[_c('t-card',{staticClass:"pb-8",attrs:{"variant":"deep"}},[_c('div',{staticClass:"mb-10"},[_c('TogglerBase',{scopedSlots:_vm._u([{key:"toggler",fn:function(ref){
var isShown = ref.isShown;
return [_c('t-button',{staticClass:"mb-2 toggler-fixed-width",attrs:{"variant":"info-light"}},[_c('SvgIcon',{staticClass:"mr-2 text-gray-darkest",attrs:{"icon":"history"}}),_c('span',[_vm._v("History of Transmission")]),_c('TogglerArrow',{attrs:{"isShown":isShown,"classes":"ml-2"}})],1)]}}],null,false,1497725218)},[_c('TransmissionHistory')],1)],1),_c('div',{staticClass:"mb-10"},[_c('TogglerBase',{attrs:{"shownAtFirst":true},scopedSlots:_vm._u([{key:"toggler",fn:function(ref){
var isShown = ref.isShown;
return [_c('t-button',{staticClass:"mb-2 toggler-fixed-width",attrs:{"variant":"pink"}},[_c('SvgIcon',{staticClass:"mr-2 text-gray-darkest",attrs:{"icon":"attachment"}}),_c('span',[_vm._v("Select Docs for Transmit")]),_c('TogglerArrow',{attrs:{"isShown":isShown,"classes":"ml-2"}})],1)]}}],null,false,845436487)},[_c('div',{staticClass:"flex gap-4 w-full"},[_c('div',{staticClass:"md:w-1/2"},[_c('t-card',{staticClass:"mb-4 h-full"},[_c('DocumentSelection')],1)],1),_c('div',{staticClass:"md:w-1/2"},[_c('t-card',{staticClass:"mb-4 h-full"},[_c('DocumentUpload')],1)],1)])])],1),_c('div',{staticClass:"mb-10"},[_c('TogglerBase',{attrs:{"shownAtFirst":true},scopedSlots:_vm._u([{key:"toggler",fn:function(ref){
var isShown = ref.isShown;
return [_c('t-button',{staticClass:"mb-2 toggler-fixed-width",attrs:{"variant":"warning"}},[_c('SvgIcon',{staticClass:"mr-2 text-gray-darkest",attrs:{"icon":"config-bars"}}),_c('span',[_vm._v("Prepare")]),_c('TogglerArrow',{attrs:{"isShown":isShown,"classes":"ml-2"}})],1)]}}],null,false,269090574)},[_c('div',{staticClass:"flex gap-4 w-full"},[_c('div',{staticClass:"md:w-full"},[_c('t-card',{staticClass:"mb-4"},[_c('DocumentEditor')],1)],1)])])],1),_c('div',{staticClass:"mb-10"},[_c('TogglerBase',{attrs:{"shownAtFirst":true},scopedSlots:_vm._u([{key:"toggler",fn:function(ref){
var isShown = ref.isShown;
return [_c('t-button',{staticClass:"mb-2 toggler-fixed-width",attrs:{"variant":"neutral"}},[_c('SvgIcon',{staticClass:"mr-2 text-gray-darkest",attrs:{"icon":"docs"}}),_c('span',[_vm._v("Preview")]),_c('TogglerArrow',{attrs:{"isShown":isShown,"classes":"ml-2"}})],1)]}}],null,false,3939599460)},[_c('div',{staticClass:"flex w-full"},[_c('t-card',{staticClass:"mb-4 w-full"},[_c('SignDocumentPreview')],1)],1)])],1),_c('div',{staticClass:"mb-10"},[_c('TogglerBase',{attrs:{"shownAtFirst":true},scopedSlots:_vm._u([{key:"toggler",fn:function(ref){
var isShown = ref.isShown;
return [_c('t-button',{staticClass:"mb-2 toggler-fixed-width",attrs:{"variant":"teal"}},[_c('SvgIcon',{staticClass:"mr-2 text-white",attrs:{"icon":"back-arrow"}}),_c('span',[_vm._v("Transmit")]),_c('TogglerArrow',{attrs:{"isShown":isShown,"classes":"ml-2"}})],1)]}}],null,false,3994955216)},[_c('div',{staticClass:"flex w-full"},[_c('t-card',{staticClass:"mb-4 w-full"},[_c('SendDocuments')],1)],1)]),_c('DocumentSendSubmit')],1)])],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }