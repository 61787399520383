<template>
  <div class="wrapper" id="document-list">
    <h2 class="h2 mb-5">Documents for MXFSign</h2>
    <ul v-if="docListGet.length > 0">
      <li
        v-for="item in docListGet"
        :key="item.id"
        class="flex justify-between items-center rounded bg-gray-lighter mb-1 px-4 py-2"
      >
        <span class="font-medium">{{ item.name }}</span>
        <div class="flex gap-2">
          <t-button class="icon-button" @click="deleteItem(item.id)" variant="dangerIcon"
            ><SvgIcon icon="remove" class="text-2xl text-red"></SvgIcon
          ></t-button>
          <t-button class="icon-button bg-blue-lightest" @click="editItem(item.id)" variant="infoIcon"
            ><SvgIcon icon="visibility" class="text-xl text-blue"></SvgIcon
          ></t-button>
        </div>
      </li>
    </ul>
    <p v-else class="text-gray-dark">No document is selected.</p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DocumentList",
  computed: {
    ...mapGetters("doc", ["docListGet"]),
  },
  methods: {
    ...mapActions("doc", ["docRemove"]),
    ...mapActions("editor", ["activeDocIdUpdate"]),
    deleteItem(id) {
      this.docRemove(id);
      setTimeout(() => {
        this.resetIframeHeight();
      }, 500);
    },
    editItem(id) {
      this.activeDocIdUpdate(id);
    },
  },
};
</script>

<style scoped>
.wrapper {
  scroll-margin-top: 80px;
}
.icon-button {
  width: 48px;
  height: 48px;
  @apply flex justify-center items-center;
}
</style>
