<template>
  <div class="flex document-editor" :class="{ 'adding-new-addible': activeAddibleGet }">
    <div class="w-4/12 toolbar-wrapper flex-shrink-0 lg:pr-2">
      <div class="flex flex-col">
        <DocumentList />     
        <hr class="my-3" v-if="activeDoc" />   
        <AddiblesList v-if="activeDoc" :doc="activeDoc" class="sticky top-4" />
      </div>
    </div>
    <div class="document-viewer-scroller overflow-y-auto p-1" :style="{ height: `${viewerHeight}px` }">
      <div class="wrapper flex-grow document-viewer-w relative">
        <div v-if="activeDoc">
          <p
            v-if="activeAddibleGet"
            class="absolute top-0 left-0 z-10 p-1 pt-1 opacity-70 h-0 pb-0 pr-0 m-0 select-none"
          >
            Now click this area to add it
          </p>
          <DocumentViewer :doc="activeDoc" />
          <AddiblesOverlay :doc="activeDoc" :pagesCount="activeDoc.pagesCount" />
        </div>

        <div class="text-gray-dark px-4 py-2" v-else>No document is selected for edit.</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DocumentViewer from "@/components/DocumentViewer/DocumentViewer.vue";
import AddiblesList from "@/components/DocumentEditor/AddiblesList.vue";
import AddiblesOverlay from "@/components/DocumentEditor/AddiblesOverlay.vue";
import DocumentList from "@/components/DocumentList/DocumentList.vue";

export default {
  name: "DocumentEditor",
  components: { DocumentViewer, AddiblesList, AddiblesOverlay, DocumentList },
  data: () => ({
    pdfFile: null,
  }),
  computed: {
    ...mapGetters("doc", ["docByIdGet"]),
    ...mapGetters("editor", ["activeDocIdGet", "activeAddibleGet"]),
    activeDoc() {
      return this.docByIdGet(this.activeDocIdGet);
    },
    viewerHeight() {
      return window.outerHeight - 128;
    },
  },
};
</script>

<style scoped>
.toolbar-wrapper {
  max-width: 288px;
}
</style>
