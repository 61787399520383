<template>
  <div>
    <div class="flex gap-3 mb-8">
      <t-button @click="activeTab = 'summary'" :variant="optionsButtonVariant">Summary</t-button>
      <t-button @click="activeTab = 'options'" :variant="summaryButtonVariant">Options</t-button>
    </div>

    <div class="flex gap-4" v-show="activeTab === 'summary'">
      <div class="w-1/2">
        <RecipientsList />
      </div>
      <div class="w-1/2">
        <DocumentListMini />
      </div>
    </div>

    <div class="" v-show="activeTab === 'options'">
      <div class="w-full">
        <EmailOptions />
      </div>
    </div>
  </div>
</template>

<script>
import DocumentListMini from "@/components/DocumentList/DocumentListMini.vue";
import RecipientsList from "@/components/SendDocuments/RecipientsList.vue";
import EmailOptions from "@/components/SendDocuments/EmailOptions.vue";
export default {
  name: "EmailMetaFields",
  components: { DocumentListMini, RecipientsList, EmailOptions },
  data: () => ({
    activeTab: "summary",
  }),
  computed: {
    optionsButtonVariant() {
      return this.activeTab === "summary" ? "success" : "success-outline";
    },
    summaryButtonVariant() {
      return this.activeTab === "options" ? "success" : "success-outline";
    },
  },
};
</script>

<style scoped></style>
