<template>
  <div>
    <label :for="`id_expiration_${_uid}`" class="label block">
      <h4 class="mb-1 text-blue">Expiration</h4>
    </label>
    <div :id="`id_expiration_${_uid}`" class="flex gap-2 justify-start items-center py-3">
      <span class="mr-2">Envelope Expires on:</span>
      <t-datepicker @change="_expiresAtUpdate" v-model="expireDate" :minDate="minDate" placeholder="Pick a date" />
      <div class="flex items-center gap-2" v-show="expireDate">
        <t-input :value="daysFromNow" class="days-from-now cursor-not-allowed" readonly></t-input>
        <span>Days from Now</span>
      </div>
    </div>

    <!-- <label :for="`id_recipient_priv_${_uid}`" class="label block">
      <h4 class="mb-1 text-blue">Recipient Privileges</h4>
    </label>
    <div :id="`id_recipient_priv_${_uid}`" class="border-b py-3 mb-3">
      <t-checkbox class="mb-1" v-model="allowSignOnPaper">Allow Recipients To Sign On Paper</t-checkbox>
      <t-checkbox v-model="allowChangeSigningResponsibility"
        >Allow Recipients To Change Signing Responsibility</t-checkbox
      >
    </div> -->

    <!-- <label :for="`id_comments_${_uid}`" class="label block">
      <h4 class="mb-1 text-blue">Comments</h4>
    </label>
    <div :id="`id_comments_${_uid}`" class="py-3">
      <t-checkbox v-model="enableComments">Enable Comments</t-checkbox>
    </div> -->
  </div>
</template>

<script>
import { addDays, differenceInDays, parseISO, format, endOfDay } from "date-fns";
import { mapActions } from "vuex";
const todayEnd = endOfDay(new Date());

export default {
  name: "EmailOptions",
  data: () => ({
    minDate: todayEnd,
    expireDate: format(addDays(todayEnd, 120), "yyyy-MM-dd"),
    // allowSignOnPaper: false,
    // allowChangeSigningResponsibility: false,
    // enableComments: false,
  }),
  computed: {
    daysFromNow() {
      if (!this.expireDate) return "";

      const expireDateEndOfDay = endOfDay(parseISO(this.expireDate));
      return differenceInDays(expireDateEndOfDay, todayEnd);
    },
  },
  mounted() {
    this._expiresAtUpdate(this.expireDate);
  },
  methods: {
    ...mapActions("signRequest", ["expiresAtUpdate"]),
    _expiresAtUpdate(expireDate) {
      this.expiresAtUpdate(expireDate);
    },
  },
};
</script>

<style scoped>
.days-from-now {
  width: 58px;
  height: 40px;
}
</style>
