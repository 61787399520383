<template>
  <!-- vue-drag-resize documentation https://github.com/kirillmurashov/vue-drag-resize -->
  <div class="overlay-wrapper">
    <div v-for="(pageNumber, pageIndex) in pagesCount" :key="pageNumber">
      <template v-if="isPageReady(pageNumber)">
        <portal :to="`page-overlay-${pageNumber}`">
          <div
            v-if="activeAddibleGet"
            class="h-full w-full click-overlay"
            @click="onClickOverlay($event, pageNumber)"
          ></div>
        </portal>
        <portal :to="`pdf-viewer-doc-${doc.id}-page-${pageNumber}`" slim>
          <VueDragResize
            v-for="addible in pageAddibles[pageIndex]"
            :key="addible.id"
            :isResizable="false"
            :isActive="false"
            :x="addible.x"
            :y="addible.y"
            :w="addiblesWidth"
            :h="addiblesHeight"
            v-on:dragstop="(rect) => onAddibleDragStop(rect, pageNumber, addible)"
            v-on:dragging="(rect) => onAddibleDragStart(rect, pageNumber, addible)"
            :parentLimitation="true"
            :parentW="wrapperWidth"
            :parentH="wrapperHeight"
          >
            <AddibleOverlayItem
              :removable="true"
              :draggable="true"
              @remove="remove(addible)"
              class="addible-item h-full relative"
              :class="{ 'is-dragging': isDragging }"
              :addible="addible"
            />
          </VueDragResize>
        </portal>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueDragResize from "vue-drag-resize";
import AddibleOverlayItem from "@/components/Addible/AddibleOverlayItem.vue";
import { getRelativeDimensions, getRealDimensions } from "@/services/addiblePosisioning.js";
import windowResizeListener from "@/mixins/windowResizeListener.js";
import { v4 as uuidv4 } from "uuid";
import { bus } from "@/main";

export default {
  name: "AddiblesOverlay",
  mixins: [windowResizeListener],
  components: { AddibleOverlayItem, VueDragResize },
  props: {
    pagesCount: {
      type: Number,
      default: 0,
    },
    doc: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isDragging: false,
    localDocAddibles: [],
    draggingAddible: null,
    wrapperElement: document.getElementsByClassName("document-viewer-w")[0],
    wrapperWidth: 0,
    wrapperHeight: 0,
    addiblesWidth: 220,
    addiblesHeight: 110,
  }),
  watch: {
    "doc.id": {
      immediate: true,
      handler() {
        this.setLocalAddibles();
      },
    },
  },
  computed: {
    ...mapGetters("editor", ["activeAddibleGet", "docRenderedPageNumbersGet"]),
    ...mapGetters("doc", ["docAddiblesGet"]),
    pageAddibles() {
      let result = [];
      for (let docPageNumber = 1; docPageNumber <= this.doc.pagesCount; docPageNumber++) {
        const pageAddibles = this.localDocAddibles.filter((addible) => addible.page === docPageNumber);
        result[docPageNumber - 1] = pageAddibles;
      }
      return result;
    },
  },
  created() {
    bus.$on("window-resized", this.onWindowResize);
  },
  methods: {
    ...mapActions("editor", ["activeAddibleUpdate"]),
    ...mapActions("doc", ["addibleAdd", "addibleUpdate", "addibleRemove"]),
    mounted() {
      this.setWrapperWidthHeight();
    },
    setWrapperWidthHeight() {
      this.wrapperWidth = this.wrapperElement.clientWidth;
      this.wrapperHeight = this.wrapperElement.clientHeight;
    },
    onWindowResize() {
      this.setWrapperWidthHeight();
      this.setLocalAddibles();
    },
    setLocalAddibles() {
      this.localDocAddibles = this.docAddiblesGet(this.doc.id).map((addible) =>
        getRealDimensions(addible, this.doc.pagesDimensionRatio, this.wrapperElement)
      );
    },
    isPageReady(pageNumber) {
      return this.docRenderedPageNumbersGet.includes(pageNumber);
    },
    onAddibleDragStart(rect, pageNumber, addible) {
      if (!this.isDragging) {
        this.isDragging = true;
      }
    },
    onAddibleDragStop(rect, pageNumber, addible) {
      this.isDragging = false;
      const relativeAddible = getRelativeDimensions(
        addible,
        {
          left: rect.left,
          top: rect.top,
          pageNumber,
        },
        this.doc.pagesDimensionRatio,
        this.wrapperElement
      );
      // fixme: relativeAddible does not have an id, it cause duplicate addibles
      this.addibleUpdate(relativeAddible);
    },

    remove(addible) {
      this.addibleRemove(addible);
      this.setLocalAddibles();
    },

    onClickOverlay(event, pageNumber) {
      if (!this.activeAddibleGet) {
        console.log("cancel");
        return;
      }

      const parentPosition = event.target.getBoundingClientRect();
      const left = event.clientX - parentPosition.x;
      const top = event.clientY - parentPosition.y;
      const newAddible = {
        id: uuidv4(),
        docId: this.doc.id,
        name: this.activeAddibleGet.name,
        page: pageNumber,
        x: left,
        y: top,
        iconName: this.activeAddibleGet.iconName,
        type: this.activeAddibleGet.type,
      };

      this.localDocAddibles.push(newAddible);
      const relativeAddible = getRelativeDimensions(
        newAddible,
        {
          left,
          top,
          pageNumber,
        },
        this.doc.pagesDimensionRatio,
        this.wrapperElement
      );
      this.addibleAdd(relativeAddible);
      this.activeAddibleUpdate(null);
    },
  },
};
</script>

<style>
.vdr.active:before {
  outline: none;
}
.adding-new-addible .pdf-page {
  outline: 2px dashed #00a2e8;
}
/* .vdr.active .addible-item {
} */
</style>
