<template>
  <div>
    <div v-for="(pageNumber, pageIndex) in pagesCount" :key="pageNumber">
      <template v-if="isPageReady(pageNumber)">
        <portal :to="`pdf-viewer-doc-${doc.id}-page-${pageNumber}-preview`" slim>
          <div
            v-for="addible in pageAddibles[pageIndex]"
            :key="addible.id"
            class="absolute"
            :style="{ left: `${addible.x}px`, top: `${addible.y}px`, width: '220px', height: '110px' }"
          >
            <AddibleOverlayItem class="addible-item h-full relative" :addible="addible" />
          </div>
        </portal>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddibleOverlayItem from "@/components/Addible/AddibleOverlayItem.vue";
import { getRealDimensions } from "@/services/addiblePosisioning.js";
import windowResizeListener from "@/mixins/windowResizeListener.js";
import { bus } from "@/main";

export default {
  name: "AddiblesOverlayPreview",
  mixins: [windowResizeListener],
  components: { AddibleOverlayItem },
  props: {
    pagesCount: {
      type: Number,
      default: 0,
    },
    doc: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    localDocAddibles: [],
  }),
  computed: {
    ...mapGetters("editor", ["activeAddibleGet", "docRenderedPageNumbersGet"]),
    ...mapGetters("doc", ["docAddiblesGet"]),
    pageAddibles() {
      let result = [];
      for (let docPageNumber = 1; docPageNumber <= this.doc.pagesCount; docPageNumber++) {
        const pageAddibles = this.localDocAddibles.filter((addible) => addible.page === docPageNumber);
        result[docPageNumber - 1] = pageAddibles;
      }
      return result;
    },
  },
  created() {
    bus.$on("addibles-updated", this.setLocalAddibles);
    bus.$on("window-resized", this.onWindowResize);
    this.setLocalAddibles();
  },
  methods: {
    ...mapActions("editor", ["activeAddibleUpdate"]),
    ...mapActions("doc", ["addibleAdd", "addibleUpdate"]),
    onWindowResize() {
      this.setLocalAddibles();
    },
    setLocalAddibles() {
      const wrapperElement = document.getElementsByClassName("document-previewer-barrier")[0];
      this.localDocAddibles = this.docAddiblesGet(this.doc.id).map((addible) => {
        return getRealDimensions(addible, this.doc.pagesDimensionRatio, wrapperElement);
      });
    },
    isPageReady(pageNumber) {
      return this.docRenderedPageNumbersGet.includes(pageNumber);
    },
  },
};
</script>
