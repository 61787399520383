<template>
  <div>
    <ul>
      <li
        v-for="addible in availableAddibles"
        :key="addible.id"
        class="addible-item flex justify-between items-center rounded mb-1 p-0 h-12 relative"
        :class="{
          'has-similar-addibles': hasSimilarAddibles(addible),
          'is-selected': isSelected(addible),
        }"
      >
        <div class="flex absolute inset-0">
          <div class="relative flex-grow">
            <a
              @click="onClickAddible(addible)"
              class="select-none addible-item-link cursor-pointer rounded absolute inset-0 flex items-center"
            >
              <span class="flex justify-center items-center w-12 h-12 rounded flex-shrink-0"
                ><SvgIcon class="text-black" :icon="addible.iconName"></SvgIcon
              ></span>
              <span class="text-base font-medium leading-4 px-4">{{ addible.name }}</span>
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { availableAddibles } from "@/constants/addible.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DocumentEditorAddibles",
  computed: {
    ...mapGetters("editor", ["activeAddibleGet", "activeAddibleGet"]),
    ...mapGetters("doc", ["docAddiblesGet"]),
    availableAddibles() {
      return availableAddibles;
    },
  },
  props: {
    doc: {
      type: Object,
      required: false,
    },
  },
  methods: {
    ...mapActions("editor", ["activeAddibleUpdate"]),
    onClickAddible(addible) {
      if (this.activeAddibleGet?.id === addible.id) {
        this.activeAddibleUpdate(null);
        return;
      }
      this.activeAddibleUpdate(addible);
    },
    hasSimilarAddibles(addible) {
      return Boolean(this.docAddiblesGet(this.doc?.id).find((_addible) => _addible.type === addible.type)?.id);
    },
    isSelected(addible) {
      if (!this.activeAddibleGet) {
        return false;
      }
      return this.activeAddibleGet?.type === addible.type;
    },
  },
};
</script>

<style scoped>
.addible-item-link {
  @apply bg-orange;
}
.addible-item.is-selected .addible-item-link {
  @apply bg-blue;
}
</style>
