<template>
  <div class="document-previewer-w">
    <h2 class="h2 mb-5">Review Doc Before Transmit</h2>
    <div class="document-viewer-scroller overflow-y-auto p-1" :style="{ maxHeight: `${viewerHeight}px` }">
      <div class="document-previewer-barrier mx-auto">
        <div v-for="doc in docListGet" :key="doc.id">
          <DocumentViewer :doc="doc" />
          <AddiblesOverlayPreview :doc="doc" :pagesCount="doc.pagesCount" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DocumentViewer from "@/components/DocumentViewer/DocumentViewer.vue";
import AddiblesOverlayPreview from "@/components/DocumentPreview/AddiblesOverlayPreview.vue";

export default {
  name: "SignDocumentPreview",
  components: { DocumentViewer, AddiblesOverlayPreview },
  provide: {
    isPreview: true,
    shouldUpdateDocRenderedPageNumbers: false,
  },
  computed: {
    ...mapGetters("doc", ["docListGet"]),
    viewerHeight() {
      return window.outerHeight - 128;
    },
  },
};
</script>

<style scoped>
.document-previewer-barrier {
  max-width: 720px;
}
</style>
