<template>
  <div>
    <h2 class="h2 mb-5">Select Doc</h2>
    <h3 class="mb-4 text-md font-medium flex items-center gap-2">
      <div class="h-12 w-4 bg-red-light rounded-lg"></div>
      <span>Doc's Library</span>
    </h3>

    <div class="flex gap-3 mb-8">
      <div class="w-1/3">
        <label class="text-sm">Doc's Category</label>
        <t-select
          v-model="chosenCategoryId"
          @input="onChangeCategory"
          placeholder="Select"
          :options="categoriesGet"
          textAttribute="name"
          valueAttribute="id"
        ></t-select>
      </div>
      <div class="w-1/3">
        <label class="text-sm">Doc's Sub-Category</label>
        <t-select
          v-model="chosenSubCategoryId"
          @input="onChangeSubCategory"
          :disabled="!chosenCategoryId"
          placeholder="Select"
          :options="subCategoriesList"
          textAttribute="name"
          valueAttribute="id"
        ></t-select>
      </div>
      <div class="w-1/3 pt-6">
        <t-button
          @click="submitSelectedItems"
          :disabled="!chosenSubCategoryId || !chosenFileItems.length"
          class="px-8"
          variant="success"
          >Select</t-button
        >
      </div>
    </div>

    <div v-if="chosenSubCategoryId" class="flex gap-3 text-md">
      <div class="px-2">
        <t-checkbox class="mb-4 block" v-model="allFilesSelected" @change="onChangeSelectAll"> Select All </t-checkbox>

        <div class="flex gap-4 flex-wrap mb-8">
          <t-checkbox-group
            :options="chosenSubCategoryItem.files"
            class="flex items-center flex-wrap gap-y-2"
            v-model="chosenFileItems"
            textAttribute="name"
            valueAttribute="id"
          >
          </t-checkbox-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DocumentSelection",
  data: () => ({
    chosenCategoryId: null,
    chosenSubCategoryId: null,
    subCategoriesList: [],
    chosenFileItems: [],
    allFilesSelected: false,
  }),
  mounted() {
    this.categoriesFetch();
  },
  computed: {
    ...mapGetters("appGlobal", ["categoriesGet"]),
    chosenCategoryItem() {
      return this.categoriesGet.find((item) => item.id === Number(this.chosenCategoryId));
    },
    chosenSubCategoryItem() {
      return this.subCategoriesList.find((item) => item.id === Number(this.chosenSubCategoryId));
    },
  },
  methods: {
    ...mapActions("doc", ["docAdd", "fetchPdfOfImageByUrl"]),
    ...mapActions("appGlobal", ["categoriesFetch", "subCategoriesFetch"]),
    async onChangeCategory() {
      this.chosenSubCategoryId = null;
      this.subCategoriesList = [];
      if (!this.chosenCategoryId) {
        return;
      }
      const chosenCategory = this.categoriesGet.find(
        (category) => Number(category.id) === Number(this.chosenCategoryId)
      );
      this.subCategoriesList = chosenCategory.subcategories;
    },
    onChangeSubCategory() {
      this.allFilesSelected = false;
      this.chosenFileItems = [];
    },
    onChangeSelectAll() {
      if (!this.allFilesSelected) {
        this.chosenFileItems = [];
        return;
      }
      this.chosenFileItems = this.chosenSubCategoryItem.files.map((file) => file.id);
    },
    async submitSelectedItems() {
      // if file is pdf, just fetch it and docAdd()
      // if file is image, send url to backend, fetch it's pdf file and docAdd

      const chosenFileItemsObjects = this.chosenSubCategoryItem;
      const fileItemObjects = this.chosenFileItems.map((fileId) => {
        return chosenFileItemsObjects.files.find((fileItemObject) => fileItemObject.id === fileId);
      });

      try {
        const promises = this.urlsToPromises(fileItemObjects);
        const files = await Promise.all(promises);
        files.forEach((blob, index) => {
          const objectUrl = URL.createObjectURL(blob);
          this.docAdd({ objectUrl, name: fileItemObjects[index].name, mimetype: "application/pdf", formId: fileItemObjects[index].id });
        });
      } catch (error) {
        console.log(error);
        alert(error.message);
      }

      this.clearSelection();
    },
    clearSelection() {
      this.chosenCategoryId = null;
      this.chosenSubCategoryId = null;
      this.subCategoriesList = [];
      this.chosenFileItems = [];
      this.allFilesSelected = false;
    },
    urlsToPromises(fileItems) {
      return fileItems.map((fileItem) => {
        const extension = fileItem.filetype;
        const imageFiletypes = ["png", "jpg", "jpeg"];
        if (imageFiletypes.includes(extension)) {
          return this.fetchPdfOfImageByUrl({ imageUrl: fileItem.url });
        } else if (extension === "pdf") {
          return this._fetchFile(fileItem.url);
        } else {
          throw new Error("Selected file is not supported.", fileItem);
        }
      });
    },

    _fetchFile(url) {
      return fetch(url).then(async (res) => {
        if (!res.ok) {
          return await res.json();
        }
        return res.blob();
      });
    },
  },
};
</script>

<style scoped></style>
