<template>
  <div class="recipient-list">
    <label :for="`id_${_uid}`" class="label flex items-center mb-2">
      <h4 class="mr-2">Recipients</h4>
      <t-button @click="modalShown = true" class="icon-button" variant="infoIcon"
        ><SvgIcon icon="edit" class="text-xl text-blue"></SvgIcon
      ></t-button>
    </label>
    <ul :id="`id_${_uid}`" v-for="item in recipientsGet" :key="item.uuid">
      <li class="flex justify-between items-center rounded bg-gray-lighter mb-1 px-2">
        <AvatarImage>
          <SvgIcon icon="avatar" class="text-4xl text-gray-lightest"></SvgIcon>
        </AvatarImage>
        <div class="flex flex-col flex-grow p-2 justify-start">
          <span class="text-base font-medium leading-6">{{ item.name }}</span>
          <span>{{ item.email }}</span>
        </div>
      </li>
    </ul>

    <t-modal body="" ref="modal" @closed="closeModal" v-model="modalShown" header="Edit recipient">
      <label :for="`id_name_${_uid}`" class="label mb-4 block md:w-1/2">
        <h4 class="mb-1">Full Name</h4>
        <t-input class="bg-gray-lighter" :id="`id_name_${_uid}`" v-model="name"></t-input>
      </label>

      <label :for="`id_email_${_uid}`" class="label mb-4 block md:w-1/2">
        <h4 class="mb-1">Email</h4>
        <t-input class="bg-gray-lighter" :id="`id_email_${_uid}`" v-model="email"></t-input>
      </label>

      <template v-slot:footer>
        <div class="flex justify-end gap-3">
          <t-button variant="danger" @click="closeModal">Cancel</t-button>
          <t-button variant="success" @click="save">Save</t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
import AvatarImage from "@/components/BaseComponents/AvatarImage.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "RecipientsList",
  components: { AvatarImage },
  data: () => ({
    modalShown: false,
    name: "",
    email: "",
    uuid: "",
  }),
  computed: {
    ...mapGetters("signRequest", ["recipientsGet"]),
    recipient() {
      return {
        ...this.recipientsGet[0],
        name: this.name,
        email: this.email,
        uuid: this.uuid,
      };
    },
  },
  mounted() {
    const recipient = this.recipientsGet[0];
    this.name = recipient?.name;
    this.email = recipient?.email;
    this.uuid = recipient?.uuid;
  },
  methods: {
    ...mapMutations("signRequest", ["recipientsUpdate"]),
    save() {
      this.recipientsUpdate([this.recipient]);
      this.closeModal();
    },
    closeModal() {
      this.$refs.modal.hide();
    },
  },
};
</script>
