<template>
  <div>
    <label :for="`id_${_uid}`" class="label flex items-center mb-2">
      <h4 class="mr-2">Selected Documents</h4>
    </label>
    <ul :id="`id_${_uid}`" v-if="docListGet.length > 0">
      <li
        v-for="item in docListGet"
        :key="item.id"
        class="flex justify-between items-center rounded bg-gray-lighter mb-1 px-4 py-4"
      >
        <span class="text-base font-medium leading-4">{{ item.name }}</span>
      </li>
    </ul>
    <small v-else>No documents</small>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DocumentListMini",
  computed: {
    ...mapGetters("doc", ["docListGet"]),
  },
  methods: {
    ...mapActions("doc", ["docRemove"]),
    ...mapActions("editor", ["activeDocIdUpdate"]),
    deleteItem(id) {
      this.docRemove(id);
    },
    editItem(id) {
      this.activeDocIdUpdate(id);
    },
  },
};
</script>

<style scoped></style>
