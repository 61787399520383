<template>
  <div class="page-home" v-if="globalDataFetched">
    <div class="max-w-8xl mx-auto">
      <div class="py-2 px-2 flex flex-col justify-center items-center">
        <Breadcrumb />
      </div>

      <div class="px-8">
        <t-card variant="deep" class="pb-8">
          <div class="mb-10">
            <TogglerBase>
              <template #toggler="{ isShown }">
                <t-button variant="info-light" class="mb-2 toggler-fixed-width"
                  ><SvgIcon icon="history" class="mr-2 text-gray-darkest"></SvgIcon><span>History of Transmission</span
                  ><TogglerArrow :isShown="isShown" classes="ml-2"
                /></t-button>
              </template>
              <TransmissionHistory />
            </TogglerBase>
          </div>

          <div class="mb-10">
            <TogglerBase :shownAtFirst="true">
              <template #toggler="{ isShown }">
                <t-button variant="pink" class="mb-2 toggler-fixed-width"
                  ><SvgIcon icon="attachment" class="mr-2 text-gray-darkest"></SvgIcon
                  ><span>Select Docs for Transmit</span><TogglerArrow :isShown="isShown" classes="ml-2"
                /></t-button>
              </template>

              <div class="flex gap-4 w-full">
                <div class="md:w-1/2">
                  <t-card class="mb-4 h-full">
                    <DocumentSelection />
                  </t-card>
                </div>
                <div class="md:w-1/2">
                  <t-card class="mb-4 h-full">
                    <DocumentUpload />
                  </t-card>
                </div>
              </div>
            </TogglerBase>
          </div>

          <div class="mb-10">
            <TogglerBase :shownAtFirst="true">
              <template #toggler="{ isShown }">
                <t-button variant="warning" class="mb-2 toggler-fixed-width"
                  ><SvgIcon icon="config-bars" class="mr-2 text-gray-darkest"></SvgIcon><span>Prepare</span
                  ><TogglerArrow :isShown="isShown" classes="ml-2"
                /></t-button>
              </template>
              <div class="flex gap-4 w-full">

                <div class="md:w-full">
                  <t-card class="mb-4">
                    <DocumentEditor />
                  </t-card>
                </div>
              </div>
            </TogglerBase>
          </div>

          <div class="mb-10">
            <TogglerBase :shownAtFirst="true">
              <template #toggler="{ isShown }">
                <t-button variant="neutral" class="mb-2 toggler-fixed-width"
                  ><SvgIcon icon="docs" class="mr-2 text-gray-darkest"></SvgIcon><span>Preview</span
                  ><TogglerArrow :isShown="isShown" classes="ml-2"
                /></t-button>
              </template>
              <div class="flex w-full">
                <t-card class="mb-4 w-full">
                  <SignDocumentPreview />
                </t-card>
              </div>
            </TogglerBase>
          </div>

          <div class="mb-10">
            <TogglerBase :shownAtFirst="true">
              <template #toggler="{ isShown }">
                <t-button variant="teal" class="mb-2 toggler-fixed-width"
                  ><SvgIcon icon="back-arrow" class="mr-2 text-white"></SvgIcon><span>Transmit</span
                  ><TogglerArrow :isShown="isShown" classes="ml-2"
                /></t-button>
              </template>
              <div class="flex w-full">
                <t-card class="mb-4 w-full">
                  <SendDocuments />
                </t-card>
              </div>
            </TogglerBase>

            <DocumentSendSubmit></DocumentSendSubmit>
          </div>
        </t-card>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import TogglerBase from "@/components/BaseComponents/TogglerBase.vue";
import TogglerArrow from "@/components/BaseComponents/TogglerArrow.vue";
import TransmissionHistory from "@/components/TransmissionHistory.vue";
import DocumentSelection from "@/components/DocumentSelection.vue";
import DocumentUpload from "@/components/DocumentUpload.vue";
import DocumentEditor from "@/components/DocumentEditor/DocumentEditor.vue";
import SignDocumentPreview from "@/components/DocumentPreview/SignDocumentPreview.vue";
import SendDocuments from "@/components/SendDocuments/SendDocuments.vue";
import DocumentSendSubmit from "@/components/SendDocuments/DocumentSendSubmit.vue";
import { mapActions, mapGetters } from "vuex";
import { pageTitle } from "@/services/helpers.js";
export default {
  name: "SignRequestGenerationPage",
  components: {
    TogglerBase,
    TogglerArrow,
    Breadcrumb,
    TransmissionHistory,
    DocumentSelection,
    DocumentUpload,
    DocumentEditor,
    SignDocumentPreview,
    SendDocuments,
    DocumentSendSubmit,
  },
  data: () => ({ globalDataFetched: false }),
  created() {
    document.title = pageTitle("Create a sign request");
    this.init();
  },
  computed: {
    ...mapGetters("appGlobal", ["casePersonsGet", "isInIframeGet"]),
  },
  methods: {
    ...mapActions("appGlobal", ["casePersonsFetch", "applicantPersonFetch", "companyFetch"]),
    ...mapActions("signRequest", ["recipientsUpdate"]),
    ...mapGetters("doc", ["docByIdGet"]),
    ...mapGetters("editor", ["activeDocIdGet", "activeAddibleGet"]),
    async init() {
      if (this.isInIframeGet) {
        await this.companyFetch();
        await this.applicantPersonFetch();
        await this.casePersonsFetch();
        this.copyCasePersonsToRecipients();
      }
      this.globalDataFetched = true;
    },
    copyCasePersonsToRecipients() {
      this.recipientsUpdate(this.casePersonsGet);
    },
    activeDoc() {
      return this.docByIdGet(this.activeDocIdGet);
    },
    viewerHeight() {
      return window.outerHeight - 128;
    },
  },
};
</script>
