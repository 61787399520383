var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"label mb-4 flex flex-row",attrs:{"for":("id_subject_" + _vm._uid)}},[_c('h4',{staticClass:"mb-1 label-email-from"},[_vm._v("Email Subject")]),_c('t-input',{attrs:{"id":("id_subject_" + _vm._uid),"name":"emailSubject"},on:{"blur":_vm.onEmailSubjectBlur},model:{value:(_vm.emailSubject),callback:function ($$v) {_vm.emailSubject=$$v},expression:"emailSubject"}})],1),_c('label',{staticClass:"label mb-8 flex flex-row",attrs:{"for":("id_message_" + _vm._uid)}},[_c('h4',{staticClass:"mb-1 label-email-from"},[_vm._v("Email Message")]),_c('t-textarea',{staticClass:"email-message-textarea",attrs:{"id":("id_message_" + _vm._uid),"name":"emailMessage","rows":_vm.rows},on:{"focus":function($event){_vm.rows = '5'},"blur":_vm.onEmailMessageBlur},model:{value:(_vm.emailMessage),callback:function ($$v) {_vm.emailMessage=$$v},expression:"emailMessage"}})],1),_c('div',{staticClass:"flex w-full justify-between"},[_c('t-checkbox',{staticClass:"py-3",attrs:{"variant":"danger"},on:{"change":_vm.onChangeReminders},model:{value:(_vm.automaticReminders),callback:function ($$v) {_vm.automaticReminders=$$v},expression:"automaticReminders"}},[_vm._v(" Send automatic reminders ")]),(_vm.automaticReminders)?_c('t-select',{staticClass:"reminders-select",attrs:{"textAttribute":"name","valueAttribute":"value","options":[
        { name: 'every day', value: 1 },
        { name: 'every 2 days', value: 2 },
        { name: 'every 3 days', value: 3 },
        { name: 'every 4 days', value: 4 },
        { name: 'every 5 days', value: 5 },
        { name: 'every 6 days', value: 6 },
        { name: 'every 7 days', value: 7 } ]},on:{"input":_vm.onChangeReminders},model:{value:(_vm.remindEveryXDay),callback:function ($$v) {_vm.remindEveryXDay=$$v},expression:"remindEveryXDay"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }