<template>
  <div>
    <h3 class="mb-4 text-md font-medium flex items-center gap-2">
      <div class="h-12 w-4 bg-red-light rounded-lg"></div>
      <span>Upload Docs</span>
    </h3>

    <label class="text-sm label">Document</label>
    <div class="flex flex-col md:flex-row items-end gap-3">
      <!-- <div class="w-2/3"> -->
      <div class="w-full mt-1">
        <DropzoneUploader
          @vdropzone-file-added="onAddedFile"
          ref="myVueDropzone"
          :id="`dropzone_${_uid}`"
          :options="dropzoneOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DropzoneUploader from "@/components/BaseComponents/DropzoneUploader.vue";
import { mapActions } from "vuex";
const imageExtensions = [".png", ".jpeg", ".jpg"];
const acceptedFiles = [...imageExtensions, ".pdf"];

export default {
  name: "DocumentUpload",
  components: {
    DropzoneUploader,
  },
  data: function () {
    return {
      dropzoneOptions: {
        // previewTemplate: '...',
        createImageThumbnails: true,
        acceptedFiles: acceptedFiles.join(", "),
        maxFilesize: 10,
        autoProcessQueue: false,
        // don't upload yet
        url: "x",
      },
    };
  },
  methods: {
    ...mapActions("doc", ["docAdd", "fetchPdfOfImageByFile"]),
    async onAddedFile(file) {
      let pdfFile;
      const extension = `.${file.type.split("/")[1]}`;
      if (!acceptedFiles.includes(extension)) {
        this.removeFile(file);
        alert("Invalid file type.");
        return;
      }

      if ([".pdf"].includes(extension)) {
        pdfFile = file;
      }

      if (imageExtensions.includes(extension)) {
        pdfFile = await this.fetchPdfOfImageByFile({ imageFile: file });
      }

      const objectUrl = URL.createObjectURL(pdfFile);
      this.docAdd({ objectUrl, name: file.name, mimetype: file.type, formId: -1 });
      // clear dropzone after upload
      this.removeFile(file);
    },
    removeFile(file) {
      this.$refs.myVueDropzone.removeFile(file);
    },
  },
};
</script>

<style scoped></style>
