<template functional>
  <div class="rounded-full bg-gray w-9 h-9">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AvatarImage",
};
</script>

<style scoped></style>
