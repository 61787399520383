<template>
  <div>
    <label :for="`id_subject_${_uid}`" class="label mb-4 flex flex-row">
      <h4 class="mb-1 label-email-from">Email Subject</h4>
      <t-input @blur="onEmailSubjectBlur" :id="`id_subject_${_uid}`" v-model="emailSubject" name="emailSubject" />
    </label>

    <label :for="`id_message_${_uid}`" class="label mb-8 flex flex-row">
      <h4 class="mb-1 label-email-from">Email Message</h4>
      <t-textarea
        @focus="rows = '5'"
        @blur="onEmailMessageBlur"
        :id="`id_message_${_uid}`"
        v-model="emailMessage"
        name="emailMessage"
        :rows="rows"
        class="email-message-textarea"
      />
    </label>

    <div class="flex w-full justify-between">
      <t-checkbox variant="danger" @change="onChangeReminders" v-model="automaticReminders" class="py-3">
        Send automatic reminders
      </t-checkbox>
      <t-select
        class="reminders-select"
        v-if="automaticReminders"
        v-model="remindEveryXDay"
        @input="onChangeReminders"
        textAttribute="name"
        valueAttribute="value"
        :options="[
          { name: 'every day', value: 1 },
          { name: 'every 2 days', value: 2 },
          { name: 'every 3 days', value: 3 },
          { name: 'every 4 days', value: 4 },
          { name: 'every 5 days', value: 5 },
          { name: 'every 6 days', value: 6 },
          { name: 'every 7 days', value: 7 },
        ]"
      ></t-select>
    </div>
  </div>
</template>

<script>
// import PrivateMessageForm from "@/components/SendDocuments/PrivateMessageForm.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SendDocumentsEmailFields",
  // components: { PrivateMessageForm },
  data: () => ({
    automaticReminders: false,
    remindEveryXDay: 2,
    emailSubject: "",
    emailMessage: "",
    rows: "1",
    isOpenPrivateMessageDialog: false,
  }),
  computed: {
    ...mapGetters("signRequest", ["recipientsGet", "privateMessagesGet"]),
    privateMessagesCount() {
      return Object.values(this.privateMessagesGet).filter((item) => Boolean(item)).length;
    },
  },
  methods: {
    ...mapActions("signRequest", ["emailSubjectUpdate", "emailMessageUpdate", "remindEveryXDayUpdate"]),
    onEmailSubjectBlur() {
      this.emailSubjectUpdate(this.emailSubject);
    },
    onEmailMessageBlur() {
      this.emailMessageUpdate(this.emailMessage);
    },
    onChangeReminders() {
      if (!this.automaticReminders) {
        this.remindEveryXDayUpdate(null);
        return;
      }
      this.remindEveryXDayUpdate(Number(this.remindEveryXDay));
    },
  },
};
</script>

<style scoped>
.reminders-select {
  max-width: 50%;
}
</style>
