<template>
  <t-card>
    <h2 class="font-bold mb-4">History of Transmitted Documents for MXFSign</h2>
    <t-table :data="signRequestsHistoryAdapted">
      <template slot="thead" slot-scope="props">
        <thead :class="props.theadClass">
          <tr :class="props.trClass">
            <th :class="props.thClass">Transmit Date</th>
            <th :class="props.thClass">Transmitted By</th>
            <th :class="props.thClass">Subject</th>
            <!-- <th :class="[props.thClass, 'text-center']">Unsigned Transmitted Document</th> -->
            <th :class="[props.thClass, 'text-center']">Signed / Initial Transmitted Document</th>
            <th :class="props.thClass">Date of Signing</th>
            <th :class="props.thClass">Email Address</th>
          </tr>
        </thead>
      </template>
      <template slot="row" slot-scope="props">
        <tr :class="props.trClass">
          <td :class="props.tdClass">
            {{ props.row.created_at }}
          </td>
          <td :class="props.tdClass">
            {{ props.row.applicant_name }}
          </td>
          <td :class="props.tdClass">
            {{ props.row.subject }}
          </td>
          <!-- <td :class="[props.tdClass, 'text-center']">
            <a href="todo:link-to-unsigned-doc">
              <SvgIcon class="text-blue" icon="visibility" />
            </a>
          </td> -->
          <td :class="[props.tdClass, 'text-center']">
            <a
              v-if="props.row.signed_at"
              :href="getSignedDocUrl(props.row.sign_answer_id, props.row.sign_answer_access_token)"
            >
              <SvgIcon class="text-blue" icon="visibility" />
            </a>
          </td>
          <td :class="props.tdClass">
            {{ props.row.signed_at }}
          </td>
          <td :class="props.tdClass">
            {{ props.row.recipient_email }}
          </td>
        </tr>
      </template>
    </t-table>
  </t-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatDateString, makeApiUrl } from "@/services/helpers";
import { cloneDeep } from "lodash-es";
export default {
  name: "TransmissionHistory",
  created() {
    this.signRequestsHistoryFetch();
  },
  methods: {
    ...mapActions("signRequest", ["signRequestsHistoryFetch"]),
    getSignedDocUrl(signAnswerId, accessToken) {
      return makeApiUrl(`/signed-document-file/${signAnswerId}?accessToken=${accessToken}`);
    },
  },
  computed: {
    ...mapGetters("signRequest", ["signRequestsHistoryGet"]),
    signRequestsHistoryAdapted() {
      const signRequests = cloneDeep(this.signRequestsHistoryGet);
      return signRequests.map((sr) => {
        sr.created_at = formatDateString(sr.created_at);
        sr.signed_at = formatDateString(sr.signed_at);
        return sr;
      });
    },
  },
};
</script>

<style scoped></style>
