<template>
  <nav class="breadcrumb-nav relative hidden lg:block">
    <div class="flex w-full">
      <div class="shadow-box light-shadow-1"></div>
      <div class="shadow-box light-shadow-2"></div>
      <div class="shadow-box light-shadow-3"></div>
      <div class="shadow-box light-shadow-4"></div>
      <div class="shadow-box light-shadow-5 flex-grow"></div>
      <div class="light-shadow-6 relative"></div>
    </div>
    <ul class="breadcrumb select-none font-bold">
      <li>
        <a class="cursor-default"><SvgIcon icon="checked" class="text-xs mr-2" />History of Transmission</a>
      </li>
      <li>
        <a class="cursor-default"><SvgIcon icon="checked" class="text-xs mr-2" />Select Doc</a>
      </li>
      <li>
        <a class="cursor-default"><SvgIcon icon="checked" class="text-xs mr-2" />Prepare</a>
      </li>
      <li>
        <a class="cursor-default"><SvgIcon icon="checked" class="text-xs mr-2" />Review</a>
      </li>
      <li>
        <a class="cursor-default"><SvgIcon icon="checked" class="text-xs mr-2" />Transmit</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumb",
};
</script>

<style scoped lang="scss">
$color-1: #5bceff;
$color-1-light: #82daff;
$color-2: #ffaaaa;
$color-2-light: #ffcaca;
$color-3: #ffc90e;
$color-3-light: #ffd956;
$color-4: #c3c3c3;
$color-4-light: #dbdbdb;
$color-5: #06928f;
$color-5-light: #0ab0a6;
$color-dark-gray: #111111;
.breadcrumb {
  list-style: none;
  overflow: hidden;
  padding: 0;
  padding-right: 30px;
}
.breadcrumb li {
  float: left;
}
.breadcrumb li a {
  color: $color-dark-gray;
  text-decoration: none;
  padding: 10px 0 10px 55px;
  background: $color-1;
  position: relative;
  display: block;
  float: left;
}
.breadcrumb li a:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 34px solid $color-1;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}
.breadcrumb li a:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}
.breadcrumb li:last-child a:before {
  border-left-color: $color-5;
  right: -30px;
  left: auto;
}
.breadcrumb li:first-child a {
  padding-left: 10px;
}
.breadcrumb li:nth-child(2) a {
  background: $color-2;
}
.breadcrumb li:nth-child(2) a:after {
  border-left-color: $color-2;
}
.breadcrumb li:nth-child(3) a {
  background: $color-3;
}
.breadcrumb li:nth-child(3) a:after {
  border-left-color: $color-3;
}
.breadcrumb li:nth-child(4) a {
  background: $color-4;
}
.breadcrumb li:nth-child(4) a:after {
  border-left-color: $color-4;
}
.breadcrumb li:nth-child(5) a {
  background: $color-5;
}
.breadcrumb li:nth-child(5) a:after {
  border-left-color: $color-5;
}
.breadcrumb li:last-child a {
  /* background: transparent !important; */
  color: white;
  pointer-events: none;
  cursor: default;
}
.breadcrumb li:last-child a:after {
  border: 0;
}
// .breadcrumb li a:hover {
//   background: $color-5-light;
// }
// .breadcrumb li a:hover:after {
//   border-left-color: $color-5-light !important;
// }
.breadcrumb > li:last-child {
  position: relative;
}

.shadow-box {
  height: 22px;
  top: 10px;
}
.light-shadow-1 {
  background: $color-1-light;
  width: 225px;
}
.light-shadow-2 {
  background: $color-2-light;
  width: 156px;
}
.light-shadow-3 {
  background: $color-3-light;
  width: 135px;
}
.light-shadow-4 {
  background: $color-4-light;
  width: 130px;
}
.light-shadow-5 {
  background: $color-5-light;
  width: 140px;
  box-shadow: 1px 1px 0 0px $color-5-light;
}
.light-shadow-6 {
  width: 14px;
  height: 22px;
  transform: skew(0, 58deg);
  top: 12px;
  right: 0;
  background: $color-5-light;
  opacity: 0.6;
}
</style>
