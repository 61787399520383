<template>
  <div class="mb-5 flex justify-center items-center flex-col py-2">
    <t-button @click="submit" variant="info" class="px-8">
      <SvgIcon v-if="isLoading" icon="loading-animated" class="p-0 m-0 flex text-white w-5 h-5 svg-full"></SvgIcon>
      <template v-else>Finish and Send</template>
    </t-button>

    <p v-if="error" class="text-red mt-4">{{ error }}</p>
    <p class="text-green mt-4" v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "DocumentSendSubmit",
  data: () => ({ message: "", error: "", isLoading: false }),
  methods: {
    ...mapActions("signRequest", ["submitSignRequest", "signRequestsHistoryFetch"]),
    ...mapActions("doc", ["clearDocStore"]),
    async submit() {
      this.error = "";
      this.message = "";
      this.isLoading = true;
      await this.submitSignRequest()
        .then((res) => {
          this.message = "Your document was sent successfully!";
          this.clearDocStore();
          this.$nextTick(this.resetIframeHeight);
        })
        .catch((error) => {
          this.error = error.message || error.messages[0];
        });
      this.isLoading = false;
      this.signRequestsHistoryFetch();
    },
  },
};
</script>
