<template>
  <section class="pb-6 send-documents">
    <h2 class="h2 mb-5">Send the Selected / Message to:</h2>

    <div class="flex max-w-7xl mx-auto gap-10">
      <div class="w-6/12">
        <EmailMetaFields class="border border-gray rounded-md p-5 mb-4" />
        <p class="text-sm font-medium text-gray-darkest">
          Once the envelope is completed, all recipients will receive a copy of the completed envelope.
        </p>
      </div>
      <div class="w-6/12">
        <EmailFields />
      </div>
    </div>
  </section>
</template>

<script>
import EmailFields from "@/components/SendDocuments/EmailFields.vue";
import EmailMetaFields from "@/components/SendDocuments/EmailMetaFields.vue";

export default {
  name: "SendDocuments",
  components: { EmailFields, EmailMetaFields },
};
</script>

<style>
.in-iframe .send-documents .modal-wrapper {
  top: calc(100vh - 700px);
}
</style>
