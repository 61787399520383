<template>
  <div>
    <div class="inline-flex" @click="toggle">
      <slot name="toggler" :isShown="isShown" />
    </div>
    <transition name="fade" mode="out-in">
      <div v-show="isShown">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "TogglerBase",
  props: {
    shownAtFirst: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.isShown = this.shownAtFirst;
  },
  data: () => ({
    isShown: false,
  }),
  methods: {
    toggle() {
      this.isShown = !this.isShown;
    },
  },
};
</script>

<style scoped></style>
